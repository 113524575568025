declare let XR8: any;
declare let window: any;
declare let XRExtras: any;
declare let LandingPage: any;


import { BabylonFileLoaderConfiguration, Engine, Scene, FreeCamera, TargetCamera, Mesh, Color3, StandardMaterial, Vector3, Quaternion, Vector2, AudioEngine, Sound, Node, Color4 } from "@babylonjs/core";
import "@babylonjs/materials";

import * as CANNON from "cannon";

import { appendScene } from "./scenes/tools";

export class Game {
    /**
     * Defines the engine used to draw the game using Babylon.JS and WebGL.
     */
    public engine: Engine;
    /**
     * Defines the scene used to store and draw elements in the canvas.
     */
    public scene: Scene;

    public rootUrl: string = "./scenes/_assets/";

    public audioEngine: AudioEngine;

    public root: Mesh;

    public touchPosition: Vector3;
    public touchPointFound: boolean;

    public maxTouches: number;

    public touchStartDisp: number;
    public startScale: Vector3;

    public touchStartVector: Vector3;
    public startAngle: number;

    public grid: Mesh;

    public tutorialgraphic: HTMLElement;

    /**
     * Constructor.
     */
    public constructor() {
        this.engine = new Engine(document.getElementById("renderCanvas") as HTMLCanvasElement, true, { audioEngine: true, stencil: true, preserveDrawingBuffer: true });
        this.scene = new Scene(this.engine);
        this.scene.skipFrustumClipping = true;

        this.tutorialgraphic = document.querySelector(".tutorialgraphic#touch");
        console.log("get tutorial graphic", this.tutorialgraphic);

        this._bindEvents();
        this._loadScene();
    }

    private touchStartHandler = (e) => {

        this.maxTouches = Math.max(e.touches.length, this.maxTouches);

        if (e.touches.length == 2) {
            //angle
            this.touchStartVector = new Vector3(e.touches[1].clientX - e.touches[0].clientX, e.touches[1].clientY - e.touches[0].clientY).normalize();
            this.startAngle = this.root.rotationQuaternion.toEulerAngles().y;

            //scale
            this.touchStartDisp = (new Vector3(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY)).length();
            this.startScale = this.root.scaling.clone();
        }

        if (e.touches.length == 1) {
            const x = e.touches[0].clientX / window.innerWidth
            const y = e.touches[0].clientY / window.innerHeight
            const hitTestResults = XR8.XrController.hitTest(x, y, ['FEATURE_POINT'])

            if (hitTestResults.length == 1) {
                this.touchPosition = new Vector3(hitTestResults[0].position.x, hitTestResults[0].position.y, hitTestResults[0].position.z);
                this.touchPointFound = true;
            }
        }
    }


    private touchMoveHandler = (e) => {

        // Call XrController.recenter() when the canvas is tapped with two fingers.
        // This resets the AR camera to the position specified by
        // XrController.updateCameraProjectionMatrix() above.


        if (e.touches.length === 2) {
            //angle
            var touchCurrentVector = new Vector3(e.touches[1].clientX - e.touches[0].clientX, e.touches[1].clientY - e.touches[0].clientY).normalize();
            var a = this.touchStartVector;
            var b = touchCurrentVector;
            var angle = Math.acos((a.x * b.x + a.y * b.y) / (Math.sqrt(a.x * a.x + a.y * a.y) * Math.sqrt(b.x * b.x + b.y * b.y)));
            angle = angle * Math.sign((Vector3.Dot(Vector3.Cross(a, b), Vector3.Forward())));
            var rot = this.root.rotationQuaternion.toEulerAngles();
            rot.y = this.startAngle + angle;
            this.root.rotationQuaternion.copyFrom(rot.toQuaternion());

            //scale
            var currentDisp = (new Vector2(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY)).length();
            this.root.scaling = this.startScale.scale(currentDisp / this.touchStartDisp);
        }

        const x = e.touches[0].clientX / window.innerWidth
        const y = e.touches[0].clientY / window.innerHeight
        const hitTestResults = XR8.XrController.hitTest(x, y, ['FEATURE_POINT'])

        if (hitTestResults.length == 1) {
            this.touchPosition = new Vector3(hitTestResults[0].position.x, hitTestResults[0].position.y, hitTestResults[0].position.z);
        }
    }

    private touchEndHandler = (e) => {
        if (e.touches.length == 0) {
            if (this.maxTouches == 1 && this.touchPointFound) {
                this.root.position = this.touchPosition;
                var rot = this.scene.activeCamera.absoluteRotation.toEulerAngles();
                rot.x = rot.z = 0;
                this.root.rotationQuaternion = rot.toQuaternion();
            }
            this.touchPointFound = false;
            this.maxTouches = 0;
        }
    }


    private showTutorial02 = () => {
        (document.querySelector(".tutorialgraphic#g01") as HTMLElement).style.display = "none";
        (document.querySelector(".tutorialgraphic#g02") as HTMLElement).style.display = "inherit";
        (document.querySelector(".tutorialgraphic#g03") as HTMLElement).style.display = "inherit";
        
        this.tutorialgraphic.addEventListener("click", () => {
            this.hideTutorial();
        }, { once: true });

    }

    private hideTutorial = () => {
        document.querySelectorAll(".tutorialgraphic").forEach((ele)=>{
            (ele as HTMLElement).style.display = "none";
        });
    }

    /**
     * Loads the first scene.
     */
    private async _loadScene(): Promise<void> {

        const userConfiguredAudioOutput = ({ microphoneInput, audioProcessor }) => {


            const audioContext = audioProcessor.context;

            // Create a THREE.js listener.  This listener is created within the THREE.js audio context, which
            // is also the context the MediaRecorder is using since we passed the THREE.js audio context
            // into our MediaRecorder using:
            //   MediaRecorder.configure({audioContext: THREE.AudioContext.getContext()})
            const listener = Engine.audioEngine.masterGain;
            // This connects the THREE.js audio to the audioProcessor so that all sound effects initialized
            // with this listener are part of the recorded video's audio.
            // console.log("inputs", listener.numberOfInputs);
            // console.log("outputs", listener.numberOfOutputs);
            // console.log("listener",listener);
            // console.log("audioProcessor",audioProcessor);

            listener.connect(audioProcessor);
            // This connects the THREE.js audio to the hardware output, which is the audio context's
            // destination  That way, the user can also hear the sound effects initialized with this listener.
            listener.connect(audioContext.destination);


            // you must return a node at the end.  This node is connected to the audioProcessor automatically

            // inside MediaRecorder

            return microphoneInput

        }

        XRExtras.MediaRecorder.initRecordButton()  // Adds record button
        XRExtras.MediaRecorder.initMediaPreview()  // Adds media preview and share

        XR8.MediaRecorder.configure({
            // watermarkImageUrl: require('./assets/8logo.png'),  // Adds watermark to photo/video
            // watermarkMaxWidth: 100,
            // watermarkMaxHeight: 10,

            // // This function is defined above.  It's how we specify what sounds will be part of the
            // // recorded output.  It's also how we can customize the audio graph.  It is called internally
            // // by MediaRecorder whenever the .configure() function is called and configureAudioOutput
            // // is defined.
            configureAudioOutput: userConfiguredAudioOutput,
            // // use the audio context provided by THREE.js.  That way, both the sounds we create in THREE.js
            // // and our MediaRecorder's audio nodes can all be part of the same audio context.  If the nodes
            // // are part of the same context, then they can be connected.
            audioContext: Engine.audioEngine.audioContext,
            // // AUTO automatically requests the microphone at the beginning.  The other option is MANUAL,
            // // which doesn't request the microphone at the beginning.  If you wanted access to the
            // // microphone not at the beginning, you could call XR8.MediaRecorder.requestMicrophone() during
            // // runtime.
            // requestMic: XR8.MediaRecorder.RequestMicOptions.AUTO,

            enableEndCard: false,
            // requestMic: XR8.MediaRecorder.RequestMicOptions.MANUAL,
            requestMic: XR8.MediaRecorder.RequestMicOptions.MANUAL,
        });

        LandingPage.configure({
            promptPrefix: "Scan or visit",
            promptSuffix: "on your mobile device to continue"
        });

        XR8.addCameraPipelineModules([             // Add camera pipeline modules.
            window.LandingPage.pipelineModule(),     // Detects unsupported browsers and gives hints.
            XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
            XRExtras.Loading.pipelineModule(),       // Manages the loading screen on startup.
            XRExtras.RuntimeError.pipelineModule(),  // Shows an error image on runtime error.
            XR8.CanvasScreenshot.pipelineModule(),
        ]);

        const rootUrl = this.rootUrl;

        BabylonFileLoaderConfiguration.LoaderInjectedPhysicsEngine = CANNON;

        await appendScene(this.scene, rootUrl, "../vincent_scene/scene.babylon");
        this.scene.clearColor = new Color4(1, 1, 1, 0);

        // get root
        for (var i = 0; i < this.scene.rootNodes.length; i++) {
            if (this.scene.rootNodes[i].name == "__root__")
                this.root = this.scene.rootNodes[i] as Mesh;
            if (this.scene.rootNodes[i].name == "Grid")
                this.grid = this.scene.rootNodes[i] as Mesh;
        };

        // Attach camera.
        this.scene.activeCamera.attachControl(this.engine.getRenderingCanvas(), false);

        this.scene.activeCamera._postProcesses.forEach((pp) => {
            if (pp == null)
                return;
            pp.alphaMode = 2;
            pp.forceAutoClearInAlphaMode = true;
            pp.autoClear = true;
        });

        // Connect the camera to the XR engine and show camera feed
        this.scene.activeCamera.addBehavior(XR8.Babylonjs.xrCameraBehavior(), true)
        this.engine.getRenderingCanvas().addEventListener('touchstart', this.touchStartHandler, true)  // Add touch listener.
        this.engine.getRenderingCanvas().addEventListener('touchend', this.touchEndHandler, true)  // Add touch listener.
        this.engine.getRenderingCanvas().addEventListener('touchmove', this.touchMoveHandler, true)  // Add touch listener.

        this.scene.animationGroups.forEach((animation) => {
            animation.speedRatio = 1.3;
        });

        Engine.audioEngine.setGlobalVolume(0.25);
        Engine.audioEngine.useCustomUnlockedButton = true;
        var soundbutton = document.createElement("button");
        soundbutton.type = "button";
        var soundbuttonimg = document.createElement("img");
        document.body.appendChild(soundbutton);
        soundbutton.appendChild(soundbuttonimg);
        soundbuttonimg.style.width = "100%";
        soundbuttonimg.style.height = "100%";

        soundbutton.id = "babylonUnmuteIconBtn";
        soundbuttonimg.src = rootUrl + "Sound_OFF.png";
        var music1 = new Sound("Music1", rootUrl + "audio/Gingerbread Funk V1.wav", this.scene, null);
        music1.spatialSound = false;
        music1.loop = true;
        // var music2 = new Sound("Music2", rootUrl + "/audio/Gingerbread Funk V1.wav", this.scene, null);
        var music2 = new Sound("Music2", rootUrl + "audio/Gingerbread Dance Music ALT.wav", this.scene, null);
        music2.spatialSound = false;
        music2.loop = true;
        // var music2 = new Sound("Music2", rootUrl + "/audio/Gingerbread Funk V1.wav", this.scene, null);
        var music3 = new Sound("Music3", rootUrl + "audio/Gingerbread Rap.wav", this.scene, null);
        music3.spatialSound = false;
        music3.loop = true;

        var soundstate = 0;
        soundbutton.addEventListener("click", (e) => {
            soundstate += 1;
            switch (soundstate) {
                case 4:
                    soundstate = 0;
                case 0:
                    soundbuttonimg.src = rootUrl + "Sound_OFF.png";
                    music1.stop();
                    music2.stop();
                    music3.stop();
                    this.scene.animationGroups.forEach((animation) => {
                        animation.speedRatio = 1.0;
                    });
            
                    break;
                case 1:
                    soundbuttonimg.src = rootUrl + "Sound_ON_01.png";
                    music1.play();
                    music2.stop();
                    music3.stop();
                    this.scene.animationGroups.forEach((animation) => {
                        animation.speedRatio = 1.3;
                    });
                    break;
                case 2:
                    soundbuttonimg.src = rootUrl + "Sound_ON_02.png";
                    music1.stop();
                    music2.play();
                    music3.stop();
                    this.scene.animationGroups.forEach((animation) => {
                        animation.speedRatio = 1.46;
                    });
                    break;
                case 3:
                    soundbuttonimg.src = rootUrl + "Sound_ON_03.png";
                    music1.stop();
                    music2.stop();
                    music3.play();
                    this.scene.animationGroups.forEach((animation) => {
                        animation.speedRatio = 1.06;
                    });
                    break;
            }

        });

        var centerPlacementGrid = () => {
            try {
                const hitTestResults = XR8.XrController.hitTest(0.5, 0.5, ['FEATURE_POINT'])

                if (hitTestResults.length == 1) {
                    var gridpos = new Vector3(hitTestResults[0].position.x, hitTestResults[0].position.y, hitTestResults[0].position.z);
                    this.grid.position = gridpos;

                    // this.grid.material.getEffect().setVector2("gridoffset",new Vector2(hitTestResults[0].position.x/100.0,hitTestResults[0].position.z/100.0));
                    var rot = this.scene.activeCamera.absoluteRotation.toEulerAngles();
                    rot.x = rot.z = 0;
                    this.grid.rotationQuaternion = rot.toQuaternion();
                }
            }
            catch (e) {

            }
        };

        this.root.setEnabled(false);
        this.grid.setEnabled(true);
        (document.querySelector(".tutorialgraphic#g01") as HTMLElement).style.display = "inherit";

        this.tutorialgraphic.addEventListener("click", () => {
            this.scene.clearColor = new Color4(0, 0, 0, 0);
            // this.tutorialgraphic.style.backgroundColor = "rgba(0,0,0,0.25)";

            //enable audio
            Engine.audioEngine.unlock();

            //center gb men
            this.root.setEnabled(true);
            this.root.position = this.grid.position.clone();
            var rot = this.scene.activeCamera.absoluteRotation.toEulerAngles();
            rot.x = rot.z = 0;
            this.root.rotationQuaternion = rot.toQuaternion();

            //disable grid
            this.grid.setEnabled(false);

            //show tutorial
            this.showTutorial02();

        }, { once: true });

        this.scene.onBeforeRenderObservable.add(() => {
            if (this.grid.isEnabled()) centerPlacementGrid();
        });

        // Render.
        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

    }

    /**
     * Binds the required events for a full experience.
     */
    private _bindEvents(): void {
        window.addEventListener("resize", () => this.engine.resize());
    }
}
